import React from "react"

import Layout from "../components/layout"
import { Box, Typography } from "@material-ui/core"

const IndexPage = () => (
  <Layout>
    <Box textAlign="center">
      <Box mt={4}>
        <Typography variant="h1">True Companion Learning</Typography>
      </Box>
      <Box mt={6}>
        <Typography variant="h2">School Begins Fall 2020</Typography>
      </Box>
    </Box>
  </Layout>
)

export default IndexPage
